import React from "react";
import "./App.css";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import packageJson from "../package.json";

function App() {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      height="100vh"
    >
      <Stack spacing={2} alignItems="center">
        <div
          style={{
            borderRadius: "50%",
            background: "#061ab1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexFlow: "column",
            height: "80px",
            width: "80px",
          }}
        >
          <img src={"/logo192.png"} width={"65px"} />
        </div>
        <Typography variant={"h4"}>Lost & Found Login</Typography>
        <Button
          variant="contained"
          color={"secondary"}
          size={"large"}
          fullWidth
          href={packageJson.internalUrl}
          target="_self"
          rel="noopener noreferrer"
        >
          Internal
        </Button>
        <Button
          variant="contained"
          color={"primary"}
          size={"large"}
          fullWidth
          href={packageJson.externalUrl}
          target="_self"
          rel="noopener noreferrer"
        >
          External
        </Button>
      </Stack>
    </Box>
  );
}

export default App;
